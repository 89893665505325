.firebaseAuth{
    /* background: linear-gradient(#000000, #636363); */
    background-image: url('https://firebasestorage.googleapis.com/v0/b/a51-asset-database.appspot.com/o/Images%2FIceland%20Puffin%20Overlook.jpg?alt=media&token=e97b4e84-e71b-46e5-abba-840184cb4e9d');
    background-size: 100%;
    background-position: left 90% top 50%;
    background-color: rgb(0, 0, 0);
    background-repeat: no-repeat;
    height:90vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

.firebaseLogin {
    margin-right: 50%;
}

.firebaseLogout {
    margin-right: 50%;
}