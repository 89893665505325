@import url("https://use.typekit.net/uhn7bjr.css");

.Input {
  background-color: black;
  height: 40px;
  width: 190px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: navigo, sans-serif;
  /* font-weight: 400; */
  font-style: normal;
}

.link {
  margin: 0rem;
  padding: 5.6px;
  border-radius: 7px;
}

.link > h1 {
  font-size: 15px;
  margin: 0rem;
}

@media all and (max-width: 760px) {
  .Input {
    display: none;
  }
}
