@import url("https://use.typekit.net/uhn7bjr.css");

.blog-container{
    background-color: black;
  background-position: right 0% top 32%;
  background-size: 50%;
  background-repeat: no-repeat;
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title > h1{
  padding-top: 4rem;
  font-family: rift-soft, sans-serif;
  color: White;
  font-size: 75px;
  font-weight: 400;
  font-style: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}