.success {
    background: linear-gradient(#000000, #008AE6);
    /* background-position: right 0% top 32%; */
    /* background-size: 50%; */
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
  }
  
  .cards {
    padding: 2rem;
  }
  
  /* .InfoCard {
    padding: 2rem;
  } */
  
  .success > h1 {
    font-weight: 450;
  }