.colorPick{
    height: 120vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: #000000; */
    background: linear-gradient(#000000, #8ac1e6);
    color: white;
}

.colors {
    display: flex;
    flex-direction: row;
    padding: .5rem;
    background-color: #ffffff;
    margin-bottom: 10rem;
}

.red {
    display: flex;
    flex-direction: column;
    margin: .25rem;
}

.orange {
    display: flex;
    flex-direction: column;
    margin: .25rem;
}

.yellow {
    display: flex;
    flex-direction: column;
    margin: .25rem;
}

.green {
    display: flex;
    flex-direction: column;
    margin: .25rem;
}

.blue {
    display: flex;
    flex-direction: column;
    margin: .25rem;
}

.purple {
    display: flex;
    flex-direction: column;
    margin: .25rem;
}

.black {
    display: flex;
    flex-direction: column;
    margin: .25rem;
}