@import url("https://use.typekit.net/uhn7bjr.css");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: navigo, sans-serif;
  font-weight: 100;
  font-style: normal;
}
