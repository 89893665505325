@import url("https://use.typekit.net/uhn7bjr.css");

.Navbar {
  background-color: black;
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  font-size: 20px;
  font-family: navigo, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.logo {
  margin-left: 4rem;
  justify-content: left;
}

.medium {
  margin-left: 80%;
  justify-content: right;
}

/* .nav-link > h1 {
  margin: 0rem;
} */

.nav-link > img {
  max-width: 45px;
  display: flex;
}

.blog {
  margin-right: 4rem;
  margin-left: -20%;
}

.upload {
  margin-right: 4rem;
  margin-left: 80%;
}

.logIn {
  margin-right: 4rem;
  margin-left: 80%;
}

/********************* Mobile Nav starts here **********************************/

.menu-bars {
  padding-left: 60%;
  font-size: 2rem;
  padding-top: 3%;
  justify-content: right;
  background: none;
}

.nav-menu {
  background-color: #fbfcfb;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: left;
  position: fixed;
  top: 0;
  right: -100%;
  transition: 850ms;
}

.nav-menu.active {
  right: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #000000;
  color: #fbfcfb;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #ffffff;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-right: 6rem;
}

span {
  margin-left: 16px;
}

  

@media screen and (min-width: 1001px) {
  .menu-bars {
    display: none;
  }
  
  .nav-menu {
    display: none;
  }
  
  .nav-menu.active {
    display: none;
  }
  
  .nav-text {
    display: none;
  }
  
  .nav-text a {
    display: none;
  }
  
  .nav-text a:hover {
    display: none;
  }
  
  .nav-menu-items {
    display: none;
  }
  
  .navbar-toggle {
    display: none;
  }
  
  /* span {
   display: none;
  } */
  
}

@media all and (max-width: 1000px) {
  .nav-link > img {
   margin:auto;
  
  }

  .menu-bars {
    margin-left: -80%;
  }

  .Navbar {
    background-color: black;
    height: 80px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: left;
    font-size: 20px;
    font-family: navigo, sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  .logo {
    margin-left: 0rem;
    /* margin-right: 4rem; */
    justify-content: center;
    align-items: left;
  }

  .blog {
    display: none;
  }
  
  .upload {
    display: none;
  }
}