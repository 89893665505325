
@import url("https://use.typekit.net/uhn7bjr.css");


.photoInfo{
    background-color: rgb(0, 0, 0);
    height: 90vh;
    color:white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.button {
    font-family: navigo, sans-serif;
    color: White;
    font-size: 75px;
    font-weight: 400;
    font-style: normal;
}
