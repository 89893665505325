@import url("https://use.typekit.net/uhn7bjr.css");

.infoCard {
  /* background-color: rgb(255, 255, 255); */
  background-color: none;
  height: 22vh;
  width: 27.5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-style: normal;
  border: .3vh solid;
  border-radius: 1vh;
  /* color: #000000; */
  text-align: center;
  padding: 4%;
}

.infoCard > h1 {
  padding: 2rem;
  /* color: #ffffff; */
  font-size: 20px;
  font-family: navigo, sans-serif;
  font-weight: 600;
  font-style: normal;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


