@import url("https://use.typekit.net/uhn7bjr.css");

.Button {
  background-color: none;
  height: 40px;
  min-width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: navigo, sans-serif;
  /* font-weight: 400; */
  font-style: normal;
}

.link {
  margin: 0rem;
  padding: 7%;
  border-radius: 7px;
}

.link > h1 {
  font-size: 15px;
  margin: 0rem;
  text-align: center;
}

@media all and (max-width: 1000px) {
  .Button {
    display: none;
  }
}
