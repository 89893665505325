.faqs {
    background: linear-gradient(#000000, #008AE6);
    /* background-position: right 0% top 32%; */
    /* background-size: 50%; */
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
  }

  .accordion-item {
  /* border-radius: 7px; */
  padding: .5rem;
  /* margin: .1rem; */
  }

  /* .accordion-button{
    background-color: red;
    color: yellow;
    filter: brightness(50%);
  } */

  /* .accordion-button:focus {
    box-shadow: none;
    background-color: red;
  } */

  .accordion-body {
    color: black;
    /* background-color: rgba(0, 140, 255, 0.128); */
    background-color: rgba(0, 120, 255, 0.108);

  }