@import url("https://use.typekit.net/uhn7bjr.css");

.benefits {
  /* background-color: rgb(0, 0, 0); */
  /* background: linear-gradient(#636363, #ffffff); */
  background: linear-gradient(#000000, #636363);

  /* background-position: right 0% top 32%; */
  /* background-size: 50%; */
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.steps > h1 {
  color: #ffffff70;
  /* font-family: rift-soft, sans-serif; */
  /* font-size: 50px; */
  font-weight: 450;
  font-style: normal;
}

.cards{
  display: flex;
  flex-direction: row;
}

.cards > div {
  padding:5vh;
}

.demo-button {
  padding-top: 2rem;
  width: 200px;
}

@media all and (max-width: 1024px) {

  .benefits {
    height: 115vh;
  }
  
  .cards{
    display: flex;
    flex-direction: column;
  }

}