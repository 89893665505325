/* @import url(https://fonts.googleapis.com/css?family=Mr+Dafoe);
@import url(https://fonts.googleapis.com/css?family=Titillium+Web:900);
@import url(https://fonts.googleapis.com/css?family=Righteous);
@import url(https://fonts.googleapis.com/css?family=Candal);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Monoton); */

@import url("https://use.typekit.net/uhn7bjr.css");

.hook-container {
  background: url("https://firebasestorage.googleapis.com/v0/b/a51-asset-database.appspot.com/o/Images%2F007.jpg?alt=media&token=89a15422-0aad-4c9c-a763-a509937c1a35") center
    center/cover no-repeat;
  background-color: rgb(0, 0, 0);
  background-position: center top 75%;
  background-size: 60%;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
}

.hook-container > h1 {
  padding-top: 4rem;
  font-family: rift-soft, sans-serif;
  color: White;
  font-size: 75px;
  font-weight: 400;
  font-style: normal;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media all and (max-width: 900px) {
  .hook-container > h1{
    /* font-family: "Mr Dafoe";
    margin: 0;
    font-size: 3em;
    margin-top: 0em;
    color: white;
    text-shadow: 0 0 0.05em #fff, 0 0 0.2em #e21212, 0 0 0.3em #ee4343;
    transform: rotate(0deg); */
    align-items: center;
    font-size: 50px;
  }

  .hook-container {
    background: url("https://firebasestorage.googleapis.com/v0/b/a51-asset-database.appspot.com/o/Images%2F007.jpg?alt=media&token=89a15422-0aad-4c9c-a763-a509937c1a35") center
    center/cover no-repeat;
    background-color: rgb(5, 5, 5);
    background-position: center top 50%;
    background-size: 100%;
    background-repeat: no-repeat;
    height: 110vh;
    width: 100%;
  }
}

@media all and (max-width: 430px) {

  .hook-container {
    background: url("https://firebasestorage.googleapis.com/v0/b/a51-asset-database.appspot.com/o/Images%2F007.jpg?alt=media&token=89a15422-0aad-4c9c-a763-a509937c1a35") center
    center/cover no-repeat;
    background-color: rgb(5, 5, 5);
    background-position: center top 50%;
    background-size: 100%;
    background-repeat: no-repeat;
    height: 75vh;
    width: 100%;
  }

  .hook-container > h1 {
    font-size: 40px;    
  }
}

