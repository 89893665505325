@import url("https://use.typekit.net/uhn7bjr.css");

.Upload {
  /* background: url("../../Photos/A51\ Land\ Camera\ sharpened_.jpg") center center/cover no-repeat; */
  background: url("../../Photos/Yashica-1.jpg") center center/cover no-repeat;
  background-color: black;
  background-position: right 0% top 40%;
  background-size: 100%;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 350px;
  /* padding bottom was 80px
  background position top was 32 */
}

.row1 {
  display: flex;
  flex-direction: row;
}

.input {
  padding: 2rem;
}

.upload-button {
  padding: 2rem;
}

.text > h1 {
  padding-top: 4rem;
  font-family: rift-soft, sans-serif;
  color: White;
  /* color: rgb(140, 68, 27); */
  font-size: 75px;
  font-weight: 400;
  font-style: normal;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.txt {
  display: flex;
  flex-direction: row;
  font-size: 12px;
}

/* .downloadNow {
  background-color: none;
  padding: .25rem;
  border-radius: 7px;
  border: 1.5px solid;
  border-color: white;
  margin-top: 2rem;
} */

.downloadNow > h1 {
  /* padding-top: 4rem; */
  /* font-family: rift-soft, sans-serif; */
  color: #00D725;
  /* color: rgb(140, 68, 27); */
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  align-items: center;
  justify-content: center;
  padding-top: .4rem;
}