@import url("https://use.typekit.net/uhn7bjr.css");

.gallery-container {
  /* background: url("../../Photos/Yashica-1.jpg") center center/cover no-repeat; */
  /* background-color: rgb(61, 61, 61); */
  background-color: rgb(0, 0, 0);
  background-position: right 0% top 32%;
  background-size: 50%;
  background-repeat: no-repeat;
  height: 135vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
}

.gallery-container > h1 {
    padding-top: 2rem;
    font-family: rift-soft, sans-serif;
    color: White;
    /* color: rgb(140, 68, 27); */
    font-size: 75px;
    font-weight: 400;
    font-style: normal;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.hook-text > h1 {
    padding-top: 4rem;
    font-family: rift-soft, sans-serif;
    color: White;
    /* color: rgb(140, 68, 27); */
    font-size: 75px;
    font-weight: 400;
    font-style: normal;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }