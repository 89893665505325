.wallpick{
    height: 90vh;
    width: 100%;
    background: url("https://firebasestorage.googleapis.com/v0/b/a51-asset-database.appspot.com/o/Images%2FFirebase%20Auth%20Copy%20layout.jpg?alt=media&token=8f771dee-0ae5-4bb3-8c98-c8d457103806") center
    center/cover no-repeat;
    background-size: 50%;
    background-position: right 90% top 50%;
    background-color: rgb(0, 0, 0);
    color:white;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.form {
    align-items: center;
    justify-content: center;
}

.wallpick-everything {
    margin-left: 35%;
}