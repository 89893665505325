@import url("https://use.typekit.net/uhn7bjr.css");

/* .Footer {
  background-color: black;
  height: 205px;
  justify-content: center;
}
.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.column >  h1 {
  color: white;
  padding-top: 2rem;
  font-family: navigo, sans-serif;
  color: White;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
}
.email > p1 {
  color: white;
  padding-top: 4rem;
  font-family: navigo, sans-serif;
  color: White;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  display: flex;
  justify-content: left;
} 
.Footer > socials {
  display: flex;
} */

.email-text > div {
  /* margin-left: -4rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin: 4rem; */
  font-size: 20px;
  font-family: navigo, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: white;
}


.Footer {
  background-color: rgb(0, 0, 0);
  height: 205px;
  width: 100%;
  display: flex;
  flex-direction:column;
  justify-content: center;
  color: White;
}

.footer-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.email-mobile {
  background-color: black;
  background-position: right 0% top 32%;
  background-size: 50%;
  background-repeat: no-repeat;
  width: 100%;
}
.email-full {
  display: flex;
  flex-direction: row;
  margin-left: 68%;
  /* margin-right: 4rem; */
}

  @media all and (max-width: 1715px) {
    .email-full {
      display: flex;
      flex-direction: row;
      margin-left: 60%;
    }
  }

  @media all and (max-width: 1550px) {
    .email-full {
      display: flex;
      flex-direction: row;
      margin-left: 62%;
    }
  }

  @media all and (max-width: 1450px) {
    .email-full {
      display: flex;
      flex-direction: row;
      margin-left: 60%;
    }
  }


  @media all and (max-width: 1370px) {
    .email-full {
      display: flex;
      flex-direction: row;
      margin-left: 55%;
    }
  }

  @media all and (max-width: 1230px) {
    .email-full {
      display: flex;
      flex-direction: row;
      margin-left: 50%;
    }
  }

  @media all and (max-width: 1100px) {
    .email-full {
      display: flex;
      flex-direction: row;
      margin-left: 45%;
    }
  }

.socials {
  margin-left: 4rem;
  display: flex;
  flex-direction: row;
}


  .Footer > div > h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 11%;
    margin-right: 11%;
    margin-top: 2rem;
    font-family: navigo, sans-serif;
    color: White;
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
  }


  .Footer > div > h2 {
    display: flex;
      background-color: black;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50px;
      width: 100%;
      border-top: 1px solid white;
      align-items: center;
      justify-content: center;
    }



    
@media all and (max-width: 1040px) {
  .socials {
    display: none;
  }
  .email-mobile {
  margin-left: 24%;
  margin-right: 24%;
  }
  .email-full {
    display: none;
  }
.email-text > div {
  font-size: 15px;
}
}

@media all and (min-width: 1040px) {
  .email-mobile {
    display: none;
  }

  .email-text > div {
    font-size: 15px;
  }
  
}