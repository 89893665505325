@import url("https://use.typekit.net/uhn7bjr.css");

.product {
    height: 100vh;
    width: 100%;
    background-color: black;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    align-items: center;
    color: black;
}

.product > h1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-top: 2rem; */
    font-family: rift-soft, sans-serif;
    color: white;
    font-size: 75px;
    font-weight: 400;
    font-style: normal;
}

.productRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.data {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    margin-left: 20rem;
    background-color: white;
    padding: 5rem;
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
    border-radius: 7px;
    max-width: 40rem;
}

.data > h1 {
    font-family: rift-soft, sans-serif;
    color: Black;
    font-size: 25px;
    font-weight: 400;
    font-style: normal;
}

.data > h1 > p1 {
    font-size: 25px;
    font-weight: 100;
    font-style: normal;
}

.image {
    display: flex;
    flex-direction: column;
    /* align-items: start; */
    justify-content: center;
    /* padding-left: 5rem; */
    /* margin: 4rem; */
    margin-right: 20rem;
    padding: .5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    /* background-color: white; */
    border-radius: 7px;
}