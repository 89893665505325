
.wallUpload {
    background-color: rgb(0, 0, 0);
  background-position: right 0% top 40%;
  background-size: 100%;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  padding-top: 10rem;
}


  
  .wallUpload > h1 {
    color: white;
  }
  
  .wallRow1 {
    display: flex;
    flex-direction: row;
  }
  
  .wallInput {
    padding: 2rem;
    padding-top: 4rem;
  }
  
  .wall-upload-button {
    padding: 2rem;
    padding-top: 4rem;
  }
  
  .text > h1 {
    padding-top: 4rem;
    font-family: rift-soft, sans-serif;
    color: White;
    /* color: rgb(140, 68, 27); */
    font-size: 75px;
    font-weight: 400;
    font-style: normal;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .txt {
    display: flex;
    flex-direction: row;
    font-size: 12px;
  }