@import url(https://use.typekit.net/uhn7bjr.css);
@import url(https://use.typekit.net/uhn7bjr.css);
@import url(https://use.typekit.net/uhn7bjr.css);
@import url(https://use.typekit.net/uhn7bjr.css);
@import url(https://use.typekit.net/uhn7bjr.css);
@import url(https://use.typekit.net/uhn7bjr.css);
@import url(https://use.typekit.net/uhn7bjr.css);
@import url(https://use.typekit.net/uhn7bjr.css);
@import url(https://use.typekit.net/uhn7bjr.css);
@import url(https://use.typekit.net/uhn7bjr.css);
@import url(https://use.typekit.net/uhn7bjr.css);
@import url(https://use.typekit.net/uhn7bjr.css);
@import url(https://use.typekit.net/uhn7bjr.css);
@import url(https://use.typekit.net/uhn7bjr.css);
@import url(https://use.typekit.net/uhn7bjr.css);
@import url(https://use.typekit.net/uhn7bjr.css);
@import url(https://use.typekit.net/uhn7bjr.css);
@import url(https://use.typekit.net/uhn7bjr.css);
@import url(https://use.typekit.net/uhn7bjr.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: navigo, sans-serif;
  font-weight: 100;
  font-style: normal;
}

.Navbar {
  background-color: black;
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  font-size: 20px;
  font-family: navigo, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.logo {
  margin-left: 4rem;
  justify-content: left;
}

.medium {
  margin-left: 80%;
  justify-content: right;
}

/* .nav-link > h1 {
  margin: 0rem;
} */

.nav-link > img {
  max-width: 45px;
  display: flex;
}

.blog {
  margin-right: 4rem;
  margin-left: -20%;
}

.upload {
  margin-right: 4rem;
  margin-left: 80%;
}

.logIn {
  margin-right: 4rem;
  margin-left: 80%;
}

/********************* Mobile Nav starts here **********************************/

.menu-bars {
  padding-left: 60%;
  font-size: 2rem;
  padding-top: 3%;
  justify-content: right;
  background: none;
}

.nav-menu {
  background-color: #fbfcfb;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: left;
  position: fixed;
  top: 0;
  right: -100%;
  transition: 850ms;
}

.nav-menu.active {
  right: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #000000;
  color: #fbfcfb;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #ffffff;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-right: 6rem;
}

span {
  margin-left: 16px;
}

  

@media screen and (min-width: 1001px) {
  .menu-bars {
    display: none;
  }
  
  .nav-menu {
    display: none;
  }
  
  .nav-menu.active {
    display: none;
  }
  
  .nav-text {
    display: none;
  }
  
  .nav-text a {
    display: none;
  }
  
  .nav-text a:hover {
    display: none;
  }
  
  .nav-menu-items {
    display: none;
  }
  
  .navbar-toggle {
    display: none;
  }
  
  /* span {
   display: none;
  } */
  
}

@media all and (max-width: 1000px) {
  .nav-link > img {
   margin:auto;
  
  }

  .menu-bars {
    margin-left: -80%;
  }

  .Navbar {
    background-color: black;
    height: 80px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: left;
    font-size: 20px;
    font-family: navigo, sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  .logo {
    margin-left: 0rem;
    /* margin-right: 4rem; */
    justify-content: center;
    align-items: left;
  }

  .blog {
    display: none;
  }
  
  .upload {
    display: none;
  }
}
.Button {
  background-color: none;
  height: 40px;
  min-width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: navigo, sans-serif;
  /* font-weight: 400; */
  font-style: normal;
}

.link {
  margin: 0rem;
  padding: 7%;
  border-radius: 7px;
}

.link > h1 {
  font-size: 15px;
  margin: 0rem;
  text-align: center;
}

@media all and (max-width: 1000px) {
  .Button {
    display: none;
  }
}

.Upload {
  /* background: url("../../Photos/A51\ Land\ Camera\ sharpened_.jpg") center center/cover no-repeat; */
  background: url(/static/media/Yashica-1.167b1a83.jpg) center center/cover no-repeat;
  background-color: black;
  background-position: right 0% top 40%;
  background-size: 100%;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 350px;
  /* padding bottom was 80px
  background position top was 32 */
}

.row1 {
  display: flex;
  flex-direction: row;
}

.input {
  padding: 2rem;
}

.upload-button {
  padding: 2rem;
}

.text > h1 {
  padding-top: 4rem;
  font-family: rift-soft, sans-serif;
  color: White;
  /* color: rgb(140, 68, 27); */
  font-size: 75px;
  font-weight: 400;
  font-style: normal;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.txt {
  display: flex;
  flex-direction: row;
  font-size: 12px;
}

/* .downloadNow {
  background-color: none;
  padding: .25rem;
  border-radius: 7px;
  border: 1.5px solid;
  border-color: white;
  margin-top: 2rem;
} */

.downloadNow > h1 {
  /* padding-top: 4rem; */
  /* font-family: rift-soft, sans-serif; */
  color: #00D725;
  /* color: rgb(140, 68, 27); */
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  align-items: center;
  justify-content: center;
  padding-top: .4rem;
}
.Input {
  background-color: black;
  height: 40px;
  width: 190px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: navigo, sans-serif;
  /* font-weight: 400; */
  font-style: normal;
}

.link {
  margin: 0rem;
  padding: 5.6px;
  border-radius: 7px;
}

.link > h1 {
  font-size: 15px;
  margin: 0rem;
}

@media all and (max-width: 760px) {
  .Input {
    display: none;
  }
}

.infoCard {
  /* background-color: rgb(255, 255, 255); */
  background-color: none;
  height: 22vh;
  width: 27.5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-style: normal;
  border: .3vh solid;
  border-radius: 1vh;
  /* color: #000000; */
  text-align: center;
  padding: 4%;
}

.infoCard > h1 {
  padding: 2rem;
  /* color: #ffffff; */
  font-size: 20px;
  font-family: navigo, sans-serif;
  font-weight: 600;
  font-style: normal;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}



.success {
    background: linear-gradient(#000000, #008AE6);
    /* background-position: right 0% top 32%; */
    /* background-size: 50%; */
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
  }
  
  .cards {
    padding: 2rem;
  }
  
  /* .InfoCard {
    padding: 2rem;
  } */
  
  .success > h1 {
    font-weight: 450;
  }
.Blog-card {
  background-color: black;
  /* height: 200px;
  width: 1000px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* font-family: navigo, sans-serif; */
  /* font-weight: 400; */
  font-style: normal;
  /* box-shadow: 0 0 .05em #fff,
              0 0 .9em #05c4fe,
              0 0  .5em #05c4fe; */
}

.blog-link {
  height: 250px;
  width: 175px;
  margin: 0rem;
  padding: 7%;
  border-radius: 7px;
  box-shadow: 0 0 .6em rgb(215, 215, 215),
              0 0 .5em #fff;
}

.blog-link > h1 {
  font-family: rift-soft, sans-serif;
  font-size: 30px;
  margin: 0rem;
  text-align: center;
}

.blog-link > p1 {
  font-family: navigo, sans-serif;
  font-size: 15px;
  margin: 0rem;
  text-align: center;
}

@media all and (max-width: 760px) {
  .Blog-card {
    display: none;
  }
}

.blog-container{
    background-color: black;
  background-position: right 0% top 32%;
  background-size: 50%;
  background-repeat: no-repeat;
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title > h1{
  padding-top: 4rem;
  font-family: rift-soft, sans-serif;
  color: White;
  font-size: 75px;
  font-weight: 400;
  font-style: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.signIn {
  background: url('https://firebasestorage.googleapis.com/v0/b/a51-asset-database.appspot.com/o/Images%2FLamps%20B%26W.jpg?alt=media&token=54cf2e4e-7156-4ef5-a78e-a21ef1a0fdc5')
  center/cover no-repeat;
  background-color: rgb(0, 0, 0);
  background-position: right 10% top 50%;
  background-size: 60%;
  background-repeat: no-repeat;
  height: 90vh;
  width: 100%;
  display: flex;
  align-items: center;  
}

.google-buttons {
  margin-left: 10%;
  display: flex;
  flex-direction: column;
}

.Logout {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* /* .button {
  align-items: center;
  justify-content: center;
} */

.Upload-button {
  margin-top: 5rem;
  margin-bottom: -7rem;
} 
/* @import url(https://fonts.googleapis.com/css?family=Mr+Dafoe);
@import url(https://fonts.googleapis.com/css?family=Titillium+Web:900);
@import url(https://fonts.googleapis.com/css?family=Righteous);
@import url(https://fonts.googleapis.com/css?family=Candal);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Monoton); */

.hook-container {
  background: url("https://firebasestorage.googleapis.com/v0/b/a51-asset-database.appspot.com/o/Images%2F007.jpg?alt=media&token=89a15422-0aad-4c9c-a763-a509937c1a35") center
    center/cover no-repeat;
  background-color: rgb(0, 0, 0);
  background-position: center top 75%;
  background-size: 60%;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
}

.hook-container > h1 {
  padding-top: 4rem;
  font-family: rift-soft, sans-serif;
  color: White;
  font-size: 75px;
  font-weight: 400;
  font-style: normal;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media all and (max-width: 900px) {
  .hook-container > h1{
    /* font-family: "Mr Dafoe";
    margin: 0;
    font-size: 3em;
    margin-top: 0em;
    color: white;
    text-shadow: 0 0 0.05em #fff, 0 0 0.2em #e21212, 0 0 0.3em #ee4343;
    transform: rotate(0deg); */
    align-items: center;
    font-size: 50px;
  }

  .hook-container {
    background: url("https://firebasestorage.googleapis.com/v0/b/a51-asset-database.appspot.com/o/Images%2F007.jpg?alt=media&token=89a15422-0aad-4c9c-a763-a509937c1a35") center
    center/cover no-repeat;
    background-color: rgb(5, 5, 5);
    background-position: center top 50%;
    background-size: 100%;
    background-repeat: no-repeat;
    height: 110vh;
    width: 100%;
  }
}

@media all and (max-width: 430px) {

  .hook-container {
    background: url("https://firebasestorage.googleapis.com/v0/b/a51-asset-database.appspot.com/o/Images%2F007.jpg?alt=media&token=89a15422-0aad-4c9c-a763-a509937c1a35") center
    center/cover no-repeat;
    background-color: rgb(5, 5, 5);
    background-position: center top 50%;
    background-size: 100%;
    background-repeat: no-repeat;
    height: 75vh;
    width: 100%;
  }

  .hook-container > h1 {
    font-size: 40px;    
  }
}


.benefits {
  /* background-color: rgb(0, 0, 0); */
  /* background: linear-gradient(#636363, #ffffff); */
  background: linear-gradient(#000000, #636363);

  /* background-position: right 0% top 32%; */
  /* background-size: 50%; */
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.steps > h1 {
  color: #ffffff70;
  /* font-family: rift-soft, sans-serif; */
  /* font-size: 50px; */
  font-weight: 450;
  font-style: normal;
}

.cards{
  display: flex;
  flex-direction: row;
}

.cards > div {
  padding:5vh;
}

.demo-button {
  padding-top: 2rem;
  width: 200px;
}

@media all and (max-width: 1024px) {

  .benefits {
    height: 115vh;
  }
  
  .cards{
    display: flex;
    flex-direction: column;
  }

}
/* .Footer {
  background-color: black;
  height: 205px;
  justify-content: center;
}
.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.column >  h1 {
  color: white;
  padding-top: 2rem;
  font-family: navigo, sans-serif;
  color: White;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
}
.email > p1 {
  color: white;
  padding-top: 4rem;
  font-family: navigo, sans-serif;
  color: White;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  display: flex;
  justify-content: left;
} 
.Footer > socials {
  display: flex;
} */

.email-text > div {
  /* margin-left: -4rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin: 4rem; */
  font-size: 20px;
  font-family: navigo, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: white;
}


.Footer {
  background-color: rgb(0, 0, 0);
  height: 205px;
  width: 100%;
  display: flex;
  flex-direction:column;
  justify-content: center;
  color: White;
}

.footer-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.email-mobile {
  background-color: black;
  background-position: right 0% top 32%;
  background-size: 50%;
  background-repeat: no-repeat;
  width: 100%;
}
.email-full {
  display: flex;
  flex-direction: row;
  margin-left: 68%;
  /* margin-right: 4rem; */
}

  @media all and (max-width: 1715px) {
    .email-full {
      display: flex;
      flex-direction: row;
      margin-left: 60%;
    }
  }

  @media all and (max-width: 1550px) {
    .email-full {
      display: flex;
      flex-direction: row;
      margin-left: 62%;
    }
  }

  @media all and (max-width: 1450px) {
    .email-full {
      display: flex;
      flex-direction: row;
      margin-left: 60%;
    }
  }


  @media all and (max-width: 1370px) {
    .email-full {
      display: flex;
      flex-direction: row;
      margin-left: 55%;
    }
  }

  @media all and (max-width: 1230px) {
    .email-full {
      display: flex;
      flex-direction: row;
      margin-left: 50%;
    }
  }

  @media all and (max-width: 1100px) {
    .email-full {
      display: flex;
      flex-direction: row;
      margin-left: 45%;
    }
  }

.socials {
  margin-left: 4rem;
  display: flex;
  flex-direction: row;
}


  .Footer > div > h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 11%;
    margin-right: 11%;
    margin-top: 2rem;
    font-family: navigo, sans-serif;
    color: White;
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
  }


  .Footer > div > h2 {
    display: flex;
      background-color: black;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50px;
      width: 100%;
      border-top: 1px solid white;
      align-items: center;
      justify-content: center;
    }



    
@media all and (max-width: 1040px) {
  .socials {
    display: none;
  }
  .email-mobile {
  margin-left: 24%;
  margin-right: 24%;
  }
  .email-full {
    display: none;
  }
.email-text > div {
  font-size: 15px;
}
}

@media all and (min-width: 1040px) {
  .email-mobile {
    display: none;
  }

  .email-text > div {
    font-size: 15px;
  }
  
}
.Outlined-button {
  background-color: black;
  height: 40px;
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: navigo, sans-serif;
  /* font-weight: 400; */
  font-style: normal;
}

.link {
  border: 1.5px solid;
  margin: 0rem;
  padding: 7%;
  border-radius: 7px;
}

.link > h1 {
  font-size: 15px;
  margin: 0rem;
}

@media all and (max-width: 760px) {
  .Outlined-button {
    display: none;
  }
}

.example-gallery {
    background-color: rgb(0, 0, 0);
    background-position: right 0% top 32%;
    background-size: 50%;
    background-repeat: no-repeat;
    height: 120vh;
    width: 100%;
    display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  }

  .text > h1 {
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-family: rift-soft, sans-serif;
    color: White;
    font-size: 45px;
    font-weight: 400;
    font-style: normal;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .text > div {
    /* padding: 4rem; */
    color: White;
    font-size: 20px;
    font-family: navigo, sans-serif;
    font-weight: 400;
    font-style: normal;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .text > div > p1 {
    padding: 4rem;
  }
  
  .text > div > p2 {
    padding: 4rem;
  }
  
  .text > div > p3 {
    padding: 4rem;
  }

  .signUp {
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    align-items: center;
  }

  .signUp > signUp-button {
    padding: 2rem;
  }
.gallery-container {
  /* background: url("../../Photos/Yashica-1.jpg") center center/cover no-repeat; */
  /* background-color: rgb(61, 61, 61); */
  background-color: rgb(0, 0, 0);
  background-position: right 0% top 32%;
  background-size: 50%;
  background-repeat: no-repeat;
  height: 135vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
}

.gallery-container > h1 {
    padding-top: 2rem;
    font-family: rift-soft, sans-serif;
    color: White;
    /* color: rgb(140, 68, 27); */
    font-size: 75px;
    font-weight: 400;
    font-style: normal;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.hook-text > h1 {
    padding-top: 4rem;
    font-family: rift-soft, sans-serif;
    color: White;
    /* color: rgb(140, 68, 27); */
    font-size: 75px;
    font-weight: 400;
    font-style: normal;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

.photoInfo{
    background-color: rgb(0, 0, 0);
    height: 90vh;
    color:white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.button {
    font-family: navigo, sans-serif;
    color: White;
    font-size: 75px;
    font-weight: 400;
    font-style: normal;
}

.getFormData {
    background-color: #636363;
    height: 90vh;
    width: 100%;
    color: white;
}
.commerceUpload {
  /* background: url("../../Photos/A51\ Land\ Camera\ sharpened_.jpg") center center/cover no-repeat; */
  /* background: url("../../Photos/Yashica-1.jpg") center center/cover no-repeat; */
  background-color: rgb(0, 0, 0);
  background-position: right 0% top 40%;
  background-size: 100%;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  padding-top: 10rem;
  /* padding bottom was 80px
  background position top was 32 */
}

.completion > div {
  display: flex;
  flex-direction: row;
}

.commerceUpload > h1 {
  color: white;
}

.commerceRow1 {
  display: flex;
  flex-direction: row;
}

.commerceInput {
  padding: 2rem;
  padding-top: 4rem;
}

.commerce-upload-button {
  padding: 2rem;
  padding-top: 4rem;
}

.text > h1 {
  padding-top: 4rem;
  font-family: rift-soft, sans-serif;
  color: White;
  /* color: rgb(140, 68, 27); */
  font-size: 75px;
  font-weight: 400;
  font-style: normal;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.txt {
  display: flex;
  flex-direction: row;
  font-size: 12px;
}


.auctionSuccess {
    background-color: black;
    height: 90vh;
    width: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    padding-top: 10rem;
}

.auctionSuccessButtons > div {
    display: flex;
    flex-direction: row;
    margin: 4rem;
}
.firebaseAuth{
    /* background: linear-gradient(#000000, #636363); */
    background-image: url('https://firebasestorage.googleapis.com/v0/b/a51-asset-database.appspot.com/o/Images%2FIceland%20Puffin%20Overlook.jpg?alt=media&token=e97b4e84-e71b-46e5-abba-840184cb4e9d');
    background-size: 100%;
    background-position: left 90% top 50%;
    background-color: rgb(0, 0, 0);
    background-repeat: no-repeat;
    height:90vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

.firebaseLogin {
    margin-right: 50%;
}

.firebaseLogout {
    margin-right: 50%;
}
.faqs {
    background: linear-gradient(#000000, #008AE6);
    /* background-position: right 0% top 32%; */
    /* background-size: 50%; */
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
  }

  .accordion-item {
  /* border-radius: 7px; */
  padding: .5rem;
  /* margin: .1rem; */
  }

  /* .accordion-button{
    background-color: red;
    color: yellow;
    filter: brightness(50%);
  } */

  /* .accordion-button:focus {
    box-shadow: none;
    background-color: red;
  } */

  .accordion-body {
    color: black;
    /* background-color: rgba(0, 140, 255, 0.128); */
    background-color: rgba(0, 120, 255, 0.108);

  }

.auctionForm{
    background-color: rgb(0, 0, 0);
    height: 90vh;
    color:white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    padding-top: 10rem;

}

.button {
    font-family: navigo, sans-serif;
    color: White;
    font-size: 75px;
    font-weight: 400;
    font-style: normal;
}

.product {
    height: 100vh;
    width: 100%;
    background-color: black;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    align-items: center;
    color: black;
}

.product > h1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-top: 2rem; */
    font-family: rift-soft, sans-serif;
    color: white;
    font-size: 75px;
    font-weight: 400;
    font-style: normal;
}

.productRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.data {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    margin-left: 20rem;
    background-color: white;
    padding: 5rem;
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
    border-radius: 7px;
    max-width: 40rem;
}

.data > h1 {
    font-family: rift-soft, sans-serif;
    color: Black;
    font-size: 25px;
    font-weight: 400;
    font-style: normal;
}

.data > h1 > p1 {
    font-size: 25px;
    font-weight: 100;
    font-style: normal;
}

.image {
    display: flex;
    flex-direction: column;
    /* align-items: start; */
    justify-content: center;
    /* padding-left: 5rem; */
    /* margin: 4rem; */
    margin-right: 20rem;
    padding: .5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    /* background-color: white; */
    border-radius: 7px;
}
.colorPick{
    height: 120vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: #000000; */
    background: linear-gradient(#000000, #8ac1e6);
    color: white;
}

.colors {
    display: flex;
    flex-direction: row;
    padding: .5rem;
    background-color: #ffffff;
    margin-bottom: 10rem;
}

.red {
    display: flex;
    flex-direction: column;
    margin: .25rem;
}

.orange {
    display: flex;
    flex-direction: column;
    margin: .25rem;
}

.yellow {
    display: flex;
    flex-direction: column;
    margin: .25rem;
}

.green {
    display: flex;
    flex-direction: column;
    margin: .25rem;
}

.blue {
    display: flex;
    flex-direction: column;
    margin: .25rem;
}

.purple {
    display: flex;
    flex-direction: column;
    margin: .25rem;
}

.black {
    display: flex;
    flex-direction: column;
    margin: .25rem;
}

.wallUpload {
    background-color: rgb(0, 0, 0);
  background-position: right 0% top 40%;
  background-size: 100%;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  padding-top: 10rem;
}


  
  .wallUpload > h1 {
    color: white;
  }
  
  .wallRow1 {
    display: flex;
    flex-direction: row;
  }
  
  .wallInput {
    padding: 2rem;
    padding-top: 4rem;
  }
  
  .wall-upload-button {
    padding: 2rem;
    padding-top: 4rem;
  }
  
  .text > h1 {
    padding-top: 4rem;
    font-family: rift-soft, sans-serif;
    color: White;
    /* color: rgb(140, 68, 27); */
    font-size: 75px;
    font-weight: 400;
    font-style: normal;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .txt {
    display: flex;
    flex-direction: row;
    font-size: 12px;
  }
.wallpick{
    height: 90vh;
    width: 100%;
    background: url("https://firebasestorage.googleapis.com/v0/b/a51-asset-database.appspot.com/o/Images%2FFirebase%20Auth%20Copy%20layout.jpg?alt=media&token=8f771dee-0ae5-4bb3-8c98-c8d457103806") center
    center/cover no-repeat;
    background-size: 50%;
    background-position: right 90% top 50%;
    background-color: rgb(0, 0, 0);
    color:white;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.form {
    align-items: center;
    justify-content: center;
}

.wallpick-everything {
    margin-left: 35%;
}
.roig-example-gallery {
    background-color: rgb(0, 0, 0);
    background-position: right 0% top 32%;
    background-size: 50%;
    background-repeat: no-repeat;
    height: 140vh;
    width: 100%;
    display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  }

  .text > h1 {
    padding-top: 2rem;
    padding-bottom: 4rem;
    font-family: rift-soft, sans-serif;
    color: White;
    font-size: 45px;
    font-weight: 400;
    font-style: normal;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .text > div {
    /* padding: 4rem; */
    color: White;
    font-size: 20px;
    font-family: navigo, sans-serif;
    font-weight: 400;
    font-style: normal;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .text > div > p1 {
    padding: 4rem;
  }
  
  .text > div > p2 {
    padding: 4rem;
  }
  
  .text > div > p3 {
    padding: 4rem;
  }

  .signUp {
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    align-items: center;
  }

  .signUp > signUp-button {
    padding: 2rem;
  }
