@import url("https://use.typekit.net/uhn7bjr.css");

.Blog-card {
  background-color: black;
  /* height: 200px;
  width: 1000px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* font-family: navigo, sans-serif; */
  /* font-weight: 400; */
  font-style: normal;
  /* box-shadow: 0 0 .05em #fff,
              0 0 .9em #05c4fe,
              0 0  .5em #05c4fe; */
}

.blog-link {
  height: 250px;
  width: 175px;
  margin: 0rem;
  padding: 7%;
  border-radius: 7px;
  box-shadow: 0 0 .6em rgb(215, 215, 215),
              0 0 .5em #fff;
}

.blog-link > h1 {
  font-family: rift-soft, sans-serif;
  font-size: 30px;
  margin: 0rem;
  text-align: center;
}

.blog-link > p1 {
  font-family: navigo, sans-serif;
  font-size: 15px;
  margin: 0rem;
  text-align: center;
}

@media all and (max-width: 760px) {
  .Blog-card {
    display: none;
  }
}
