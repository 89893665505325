.signIn {
  background: url('https://firebasestorage.googleapis.com/v0/b/a51-asset-database.appspot.com/o/Images%2FLamps%20B%26W.jpg?alt=media&token=54cf2e4e-7156-4ef5-a78e-a21ef1a0fdc5')
  center/cover no-repeat;
  background-color: rgb(0, 0, 0);
  background-position: right 10% top 50%;
  background-size: 60%;
  background-repeat: no-repeat;
  height: 90vh;
  width: 100%;
  display: flex;
  align-items: center;  
}

.google-buttons {
  margin-left: 10%;
  display: flex;
  flex-direction: column;
}

.Logout {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* /* .button {
  align-items: center;
  justify-content: center;
} */

.Upload-button {
  margin-top: 5rem;
  margin-bottom: -7rem;
} 