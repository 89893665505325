@import url("https://use.typekit.net/uhn7bjr.css");

.roig-example-gallery {
    background-color: rgb(0, 0, 0);
    background-position: right 0% top 32%;
    background-size: 50%;
    background-repeat: no-repeat;
    height: 140vh;
    width: 100%;
    display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  }

  .text > h1 {
    padding-top: 2rem;
    padding-bottom: 4rem;
    font-family: rift-soft, sans-serif;
    color: White;
    font-size: 45px;
    font-weight: 400;
    font-style: normal;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .text > div {
    /* padding: 4rem; */
    color: White;
    font-size: 20px;
    font-family: navigo, sans-serif;
    font-weight: 400;
    font-style: normal;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .text > div > p1 {
    padding: 4rem;
  }
  
  .text > div > p2 {
    padding: 4rem;
  }
  
  .text > div > p3 {
    padding: 4rem;
  }

  .signUp {
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    align-items: center;
  }

  .signUp > signUp-button {
    padding: 2rem;
  }