@import url("https://use.typekit.net/uhn7bjr.css");

.commerceUpload {
  /* background: url("../../Photos/A51\ Land\ Camera\ sharpened_.jpg") center center/cover no-repeat; */
  /* background: url("../../Photos/Yashica-1.jpg") center center/cover no-repeat; */
  background-color: rgb(0, 0, 0);
  background-position: right 0% top 40%;
  background-size: 100%;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  padding-top: 10rem;
  /* padding bottom was 80px
  background position top was 32 */
}

.completion > div {
  display: flex;
  flex-direction: row;
}

.commerceUpload > h1 {
  color: white;
}

.commerceRow1 {
  display: flex;
  flex-direction: row;
}

.commerceInput {
  padding: 2rem;
  padding-top: 4rem;
}

.commerce-upload-button {
  padding: 2rem;
  padding-top: 4rem;
}

.text > h1 {
  padding-top: 4rem;
  font-family: rift-soft, sans-serif;
  color: White;
  /* color: rgb(140, 68, 27); */
  font-size: 75px;
  font-weight: 400;
  font-style: normal;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.txt {
  display: flex;
  flex-direction: row;
  font-size: 12px;
}
