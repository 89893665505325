
.auctionSuccess {
    background-color: black;
    height: 90vh;
    width: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    padding-top: 10rem;
}

.auctionSuccessButtons > div {
    display: flex;
    flex-direction: row;
    margin: 4rem;
}